<template>
  <v-container>
    <v-row>
      <v-col>
        <v-subheader style="font-size: 20px; color: #444">Files</v-subheader>
        <v-spacer></v-spacer>
        <div v-if="selected.length > 0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-dots-vertical</v-icon>
                <span style="margin-left: 10px;">Actions</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-on:click="importTemplates(selected)"
              >
                <v-list-item-title>
                  <v-icon small>
                    mdi-cloud-upload
                  </v-icon>
                  <span style="font-size: 14px; margin-left: 10px;">Import selected to racksnet© cloud</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!--<v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on:click="importTemplates(selected)"
                class="mx-2 indigo--text"
                medium
                icon
                color="white"
                v-on="on"
              >
                <v-icon>
                  mdi-cloud-upload
                </v-icon>
              </v-btn>
            </template>
            <span style="font-size: 11px">Import selected technologies to racksnet© cloud.</span>
          </v-tooltip>-->
          <!--<v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on:click="downloadMultipleTemplates(selected)"
                class="mx-2 indigo--text"
                small
                color="white"
                v-on="on"
              >
                <v-icon>mdi-download</v-icon>
                <span style="margin-left: 5px;">Download Templates (.zip)</span>
              </v-btn>
            </template>
            <span>The selected technologies are downloaded on local device.</span>
          </v-tooltip>-->
        </div>
      </v-col>
    </v-row>
    <v-divider style="margin-bottom: 10px;"></v-divider>
    <v-row v-if="files.length > 0">
      <v-col
        align="center"
        v-for="file in files"
        :key="file.template_id"
        cols="6"
        xs="12"
        sm="4"
        md="3"
        lg="2"
      >
        <!-- Folders -->
        <v-card class="directory-layout" v-if="file.type === 'directory'">
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" style="position: absolute; right: 3px; top: 3px; z-index: 1;">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-on:click="openDirectory(file.name, file.children)">
                <v-list-item-title>
                  <v-icon class="rn-list-icon">mdi-folder-open</v-icon>Open Folder
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-list-item align="center" v-on:click="openDirectory(file.name, file.children)">
            <v-list-item-content>
              <v-icon class="racksnet-icons">mdi-folder</v-icon>
              <v-list-item-title class="headline" >
                {{file.name}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{file.children.length + ' files inside'}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <!-- Technologies -->
        <v-card
          class="templates-layout"
          v-if="file.type === 'technology'"
          :class="{ selected: selected.includes(file) }"
        >
          <!--<v-checkbox
            dense
            v-model="selected"
            :value="file"
            class="item-checkbox"
            :class="selected.includes(file) ? 'selected' : ''"
          ></v-checkbox>-->
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" style="position: absolute; right: 3px; top: 3px; z-index: 1;">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-on:click="openTechnologyPage(file)">
                <v-list-item-title>
                  <v-icon class="rn-list-icon">mdi-magnify</v-icon>View details
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-on:click="downloadTemplate(file)">
                <v-list-item-title>
                  <v-icon class="rn-list-icon">mdi-download</v-icon> Download Templates (.zip)
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-list-item align="center" v-on:click="openTechnologyPage(file)">
            <v-list-item-content>
              <v-icon class="racksnet-icons" size="42">$vuetify.icons.racksnetTemplates</v-icon>
              <v-list-item-title class="headline">
                {{getTechnologyName(file.technology, file.name)}}
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="category === 'Search'"
                style="margin-top: -5px; font-weight: bold;"
              >({{file.vendor}})</v-list-item-subtitle>
              <v-list-item-subtitle>Technology</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <!-- No Files found handler -->
    <v-row v-else>
      <v-col>
        <v-subheader>It's really empty inside. No templates found.</v-subheader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import RequestHandler from "@/RequestHandler";

export default {
  name: "folders-component",
  props: {
    files: Array,
    notification: Object,
    category: String
  },
  data: () => ({
    selected: []
  }),
  mounted() {
    this.$root.$on("updateSelectedFiles", files => {
      this.updateSelectedFiles(files);
    });
  },
  methods: {
    getTechnologyName(technology, sub) {
      let name = "";
      if (sub && sub !== "Unnamed") {
        if (sub.includes(technology)) {
          name = `${technology} ${sub.replace(technology, '')}`
        } else {
          name = sub
        }
      } else {
        name = technology
      }
      return name
    },

    openDirectory(link, newState) {
      this.$emit("openDirectory", link, newState);
    },

    openTechnologyPage(file) {
      this.$root.$emit("openTechnologyPage", file);
    },

    showTemplateInfo(file) {
      this.$root.$emit("showTemplateInfo", file);
    },

    showNotification(message, color, icon) {
      this.$root.$emit("showNotification", message, color, icon);
    },

    updateSelectedFiles(files) {
      this.selected = files;
    },

    /*downloadTemplate(file) {
      RequestHandler.downloadTemplate(
        file.template_id,
        `${file.vendor}_${file.technology}`,
        this.showNotification
      );
    },

    downloadMultipleTemplates(files) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === "technology") {
          RequestHandler.downloadTemplate(
            files[i].template_id,
            `${files[i].vendor}_${files[i].technology}`,
            this.showNotification
          );
        }
      }
    },*/

    importTemplates(files) {
      let tpl_ids = [];
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === "file") {
          tpl_ids.push(files[i].template_id);
        }
      }
      /*RequestHandler.importTemplates(tpl_ids, this.showNotification);*/
    }
  }
};
</script>

<style scoped>
.templates-layout,
.directory-layout {
  height: 120px;
}

.templates-layout:hover,
.directory-layout:hover,
.templates-layout.selected,
.templates-layout.selected {
  background: #e8f0fe;
}
.directory-layout:hover {
  cursor: pointer;
}

.templates-layout:hover .v-list-item__title,
.directory-layout:hover .v-list-item__title,
.templates-layout.selected .v-list-item__title,
.templates-layout.selected .v-list-item__title {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
  fill: #1976d2 !important;
}
.templates-layout:hover .v-list-item .racksnet-icons,
.directory-layout:hover .v-list-item .racksnet-icons,
.templates-layout.selected .v-list-item .racksnet-icons,
.templates-layout.selected .v-list-item .racksnet-icons {
  color: #4ca9fe !important;
  caret-color: #4ca9fe !important;
  fill: #4ca9fe !important;
}
.templates-layout .item-checkbox {
  display: none;
  position: absolute;
  margin: 0;
  padding: 6px 0 0 8px;
  z-index: 1;
}
.templates-layout:hover .item-checkbox,
.templates-layout .item-checkbox.selected {
  display: block;
  display: none !important;
}
.templates-layout .v-btn:hover,
.directory-layout .v-btn:hover {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
}

.templates-layout .v-list-item__title,
.directory-layout .v-list-item__title {
  font-size: 0.9rem !important; /*0.8125rem !important;*/
  font-weight: normal;
  height: 32px;
  line-height: 32px;
}
.templates-layout .v-list-item__subtitle,
.directory-layout .v-list-item__subtitle {
  font-size: 0.7rem !important; /*0.6rem !important;*/
}
.v-list-item .racksnet-icons {
  font-size: 42px;
}
.directory-layout .v-list-item .racksnet-icons {
  color: #f0e68c;
}

.rn-list-icon {
  margin-right: 10px;
}

@media (max-width: 360px) {
  .templates-layout,
  .directory-layout {
    height: 100px;
  }

  .templates-layout .v-list-item__title,
  .directory-layout .v-list-item__title {
    font-size: 12px !important;
    /*padding: 8px 12px;*/
    height: 30px;
    line-height: 30px;
  }
  .templates-layout .v-list-item__subtitle,
  .directory-layout .v-list-item__subtitle {
    font-size: 10px !important;
    margin-top: -5px;
    /*padding: 8px 12px;*/
  }
  .v-list-item .racksnet-icons {
    font-size: 36px;
  }
}
</style>
