import router from "./router.js";
import store from "./store.js";

class SessionHandler {
	static createSession(token) {
		sessionStorage.setItem("token", token);
		// this.$cookies.set("token", token);
		store.state.isLoggedIn = true;
		store.state.alerts.show = false;
		router.push({ name: "home" }).catch((error) => {
			let e = error
			e.toString()
		});
	}

	static getSession() {
		return sessionStorage.getItem("token")
		// return this.$cookies.get("token");
	}

	static loadExistingSession() {
		if (sessionStorage.getItem("token") === undefined) {
		// if (!this.$cookies.isKey("token")) {
			store.state.isLoggedIn = false;
		} else {
			store.state.isLoggedIn = true;
		}
	}

	static updateSession(token) {
		// this.$cookies.set("token", token);
		sessionStorage.setItem("token", token);
		store.state.isLoggedIn = true;
	}

	/**
	 *
	 * @param {object} Vue
	 * @param {boolean} showInfo if this parameter is true, it is shown an modal with informations
	 *
	 */
	static handleExpiredSession(showInfo) {
		if (showInfo) {
			store.commit("showAlert", {
				message: "Session is expired. Please login again.",
			});
		}
		sessionStorage.removeItem("token")
		// this.$cookies.remove("token");
		store.state.isLoggedIn = false;
		if (router.name !== "login") {
			router.push("/login");
		}
	}
}

export default SessionHandler;
