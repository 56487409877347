<template>
  <v-app id="login">
    <v-app-bar
      id="navbar"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#337fa5"
      dark
    >
      <label class="racksnet-nav-label">
        <img
          :src="require('@/assets/img/racksnet_Logo_white-1024x299.png')"
          class="logo-image"
        />
      </label>
    </v-app-bar>

    <v-main class="fill-height racksnet-background">
      <v-container
        id="login_panel"
        class="fill-height"
        style="max-width: 1280px"
      >
        <v-row col="12" align="center" justify="center">
          <v-col cols="6">
            <v-container style="padding-right: 60px" align="left">
              <v-img
                :src="require('@/assets/icon/racksnet_Logo_Weiss_Invers.png')"
                max-height="200"
                max-width="320"
              ></v-img>
              <h1
                style="
                  font-size: 2.2rem;
                  color: white;
                  font-weight: 300;
                  margin-top: 10px;
                "
              >
                TemplateHub - Browse best-practice templates from vendors
              </h1>
            </v-container>
          </v-col>
          <v-col cols="5">
            <v-card id="loginFormCard" outlined>
              <v-container class="container fill-height">
                <v-alert
                  v-model="$store.state.alerts.show"
                  text
                  width="100%"
                  :type="$store.state.alerts.type"
                  :icon="$store.state.alerts.icon"
                >
                  {{
                    $store.state.alerts.message
                      ? $store.state.alerts.message
                      : "Login failed. Please try again or contact the support"
                  }}
                </v-alert>
                <v-card-text v-if="$store.state.isIframe" align="center">
                  <div v-if="loading === true && !$store.state.alerts.show">
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                    <br />
                    Logging in into TemplateHub...
                  </div>
                  <div v-else-if="$store.state.alerts.show">
                    Something went wrong. Please login again.
                  </div>
                </v-card-text>
                <v-card-text v-else>
                  <v-form>
                    <v-label>E-Mail Address</v-label>
                    <v-text-field
                      label="E-Mail Address"
                      type="text"
                      name="email"
                      single-line
                      outlined
                      v-model="input.email"
                    />
                    <v-label>Password</v-label>
                    <v-text-field
                      label="Password"
                      type="password"
                      name="password"
                      single-line
                      outlined
                      v-model="input.password"
                    />
                    <v-btn
                      color="#337fa5"
                      style="color: white"
                      type="button"
                      class="px-12"
                      block
                      v-on:click="login()"
                      :disabled="
                        loading || input.email === '' || input.password === ''
                      "
                      :loading="loading"
                    >
                      Login
                    </v-btn>
                  </v-form>
                  <v-list
                    v-if="this.$store.state.isIframe === false"
                    class="mt-3 d-flex justify-end"
                  >
                    <router-link to="/forgot-password">
                      <span @click="dismissAlert"> Forgot your password? </span>
                    </router-link>
                  </v-list>
                </v-card-text>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import RequestHandler from "@/RequestHandler";
import SessionHandler from "@/SessionHandler";

export default {
  name: "Login",
  data() {
    return {
      input: {
        email: "",
        password: "",
      },
      loading: false,
    };
  },
  async created() {
    try {
      this.$store.state.isIframe = false;
      if (window.self !== window.top) {
        let url = new URL(window.location.href);
        this.$store.state.isIframe = true;
        if (!url.searchParams.has("token")) {
          return this.$store.commit("showAlert", {
            message: "Invalid authorization token!",
          });
        }
        this.loading = true;
        let variant = url.searchParams.get("variant") || "cloud";
        sessionStorage.setItem("variant", variant);
        let response = await RequestHandler.tokenAuth(
          url.searchParams.get("token"),
          variant
        );
        if (response.error) {
          this.loading = false;
          return this.$store.commit("showAlert", { message: response.message });
        }
        let customers = [];
        if (url.searchParams.get("data")) {
          let data = JSON.parse(
            decodeURIComponent(url.searchParams.get("data"))
          );
          data.forEach(function (elm) {
            customers.push({
              ruid: elm.ruid,
              company: elm.company,
            });
          });
        } else {
          customers.push({
            company: "Current tenant",
            ruid: url.searchParams.get("ruid"),
          });
        }
        this.$store.state.userData = {
          email: response.email,
          puid: response.puid,
          firstname: response.firstname,
          lastname: response.lastname,
          role: response.role,
          userType: response.userType,
          plattform: response.plattform,
          apikey: response.apikey,
        };
        sessionStorage.setItem("th_user_id", response.puid);
        sessionStorage.setItem("th_customers", JSON.stringify(customers));
        SessionHandler.createSession(response.token);
        this.loading = false;
      }
    } catch (err) {
      this.loading = false;
      let errmsg = err.response.status + " " + err.response.statusText;
      if (err.response.data) {
        errmsg = err.response.data
      }
      return this.$store.commit("showAlert", { message: errmsg });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        from ? from : null;
        document.title = to.meta.title;
      },
    },
  },
  methods: {
    dismissAlert() {
      this.$store.state.alerts.show = false;
    },
    async login() {
      if (this.input.email === "" || this.input.password === "") {
        return this.$store.commit("showAlert", {
          message: "Please enter a email and password!",
        });
      }
      try {
        this.loading = true;
        let response = await RequestHandler.loginProcess(this.input);
        if (response.error) {
          this.loading = false;
          return this.$store.commit("showAlert", { message: response.message });
        }
        if (response.userType === "APPUSER") {
          let customers = JSON.parse(response.customers);
          if (Array.isArray(customers) && customers.length >= 1) {
            sessionStorage.setItem("th_customers", JSON.stringify(customers));
          } else {
            this.loading = false;
            return this.$store.commit("showAlert", {
              message: "Cannot get customers!",
            });
          }
        }
        this.loading = false;
        this.$store.state.isIframe = false;
        this.$store.state.userData = {
          email: response.email,
          puid: response.puid,
          firstname: response.firstname,
          lastname: response.lastname,
          role: response.role,
          userType: response.userType,
          plattform: response.plattform,
          apikey: response.apikey,
        };
        sessionStorage.setItem("th_user_id", response.puid);
        SessionHandler.createSession(response.token);
      } catch (err) {
        this.loading = false;
        return this.$store.commit("showAlert", { message: err });
      }
    },
  },
};
</script>

<style>
html {
  padding: 0;
  margin: 0;
  overflow-y: hidden;
}

.racksnet-background {
  background-image: url("../assets/img/Slider_Datacenter.jpg");
}
</style>
