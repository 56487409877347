<template>
  <v-app id="dashboard">
    <global-app-bar></global-app-bar>
    <v-main class="fill-height racksnet-background">
      <v-container style="padding-top: 3%">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <v-card color="grey lighten-5" raised style="padding: 15px">
              <v-card-title class="headline">
                <label style="text-align: center; margin: 0 auto;"
                  >Vendor Templates</label
                >
              </v-card-title>
              <v-card-text
                style="
                  letter-spacing: 0.05em;
                  min-height: 120px;
                  text-align: center;
                "
              >
                Browse many pre-defined Templates & Playbooks filtered by Vendors
                (e.g. Cisco Systems, DELL EMC, Hewlett Packard, CUMULUS OS …)
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue"
                  dark
                  block
                  v-on:click="goToFiles('Vendors')"
                >
                  <v-icon left>mdi-magnify</v-icon>Filter By Vendor
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card color="grey lighten-5" raised style="padding: 15px">
              <v-card-title class="headline">
                <label style="text-align: center; margin: 0 auto;"
                  >Technology Templates</label
                >
              </v-card-title>
              <v-card-text
                style="
                  letter-spacing: 0.05em;
                  min-height: 120px;
                  text-align: center;
                "
              >
                Browse many pre-defined Templates & Playbooks filtered by
                Technologies (e.g. IPSEC, VXLAN, EVPN, Spine-and-Leaf …)
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue"
                  dark
                  block
                  v-on:click="goToFiles('Technologies')"
                >
                  <v-icon left>mdi-magnify</v-icon>Filter By Technology
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
          <!--<v-col cols="12" sm="4">
            <v-card color="grey lighten-5" raised style="padding: 15px">
              <v-card-title class="headline">
                <label style="text-align: center; margin: 0 auto;"
                  >Custom Templates</label
                >
              </v-card-title>
              <v-card-text
                style="
                  letter-spacing: 0.05em;
                  min-height: 150px;
                  text-align: center;
                "
              >
                Browse many pre-defined Templates & Playbooks filtered by
                Technologies (e.g. IPSEC, VXLAN, EVPN, Spine-and-Leaf …)
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue"
                  dark
                  block
                  v-on:click="goToFiles('Technologies')"
                >
                  <v-icon left>mdi-magnify</v-icon>Search Templates
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>-->
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import RequestHandler from "@/RequestHandler";
import SessionHandler from "@/SessionHandler";
import GlobalAppBar from "@/components/GlobalAppBar.vue";

export default {
  components: { GlobalAppBar },
  name: "Home",
  data: () => ({}),
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        from ? from : null;
        document.title = to.meta.title;
      },
    },
  },
  async created() {
    if (!SessionHandler.getSession()) {
      return SessionHandler.handleExpiredSession(true);
    }

    // request data
    SessionHandler.loadExistingSession();
    this.$store.state.userData = await RequestHandler.getUserData();
  },
  methods: {
    logout() {
      sessionStorage.removeItem("th_breadcrumbs");
      sessionStorage.removeItem("th_customers");
      SessionHandler.handleExpiredSession(false);
    },
    goToFiles(tab) {
      if (tab) {
        return this.$router.push({ name: "files", params: { tab } });
      } else {
        return this.$router.push({ name: "files", params: { tab: 'Vendors' } });
      }
    },
  },
};
</script>

<style>
#app-bar .logo-wrapper {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}
#app-bar .racksnet-nav-label {
  min-width: 300px;
  margin-left: 5px;
  padding-left: 0 !important;
}
#app-bar .logo-image {
  max-width: 96px;
  max-height: 96px;
}
</style>
