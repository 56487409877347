import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import TemplateIcon from '@/assets/icon/racksnet_Icon-Template-T.vue';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      racksnetTemplates: {
        component: TemplateIcon, // you can use string here if component is registered globally
        props: { // pass props to your component if needed
          name: 'racksnetTemplates',
        },
      },
    },
  },
});
