import Vue from "vue";
import Router from "vue-router";

import LoginView from "@/views/Login.vue";
import HomeView from "@/views/Home.vue";
import TemplatesView from "@/views/Templates.vue";
import ProfileView from "@/views/Profile.vue";
import ForgotPasswordView from "@/views/ForgotPassword.vue";
import ResetPasswordView from "@/views/ResetPassword.vue";
import RegisterView from "@/views/Register.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "login"
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: { 
        title: 'Login - racksnet© TemplateHub',
        requiresAuth: false
      }
    },
    {
      path: "/register",
      name: "Register",
      component: RegisterView,
      meta: { 
        title: 'Register - racksnet© TemplateHub',
        requiresAuth: false
      }
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: ForgotPasswordView,
      meta: { 
        title: 'Forgot Password - racksnet© TemplateHub',
        requiresAuth: false
      }
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPasswordView,
      meta: { 
        title: 'Reset Password - racksnet© TemplateHub',
        requiresAuth: false
      }
    },
    {
      path: "/home",
      name: "home",
      component: HomeView,
      meta: { 
        title: 'Home - racksnet© TemplateHub',
        requiresAuth: true
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfileView,
      meta: { 
        title: 'My Profile - racksnet© TemplateHub',
        requiresAuth: true
      }
    },
    {
      path: "/files",
      name: "files",
      component: TemplatesView,
      meta: { 
        title: 'Files - racksnet© TemplateHub',
        requiresAuth: true
      }
    },
  ]
});
