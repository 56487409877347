<template>
  <v-app id="login">
    <v-app-bar
      id="navbar"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#337fa5"
      dark
    >
      <label class="racksnet-nav-label">
        <img
          :src="require('@/assets/img/racksnet_Logo_white-1024x299.png')"
          class="logo-image"
        />
      </label>
    </v-app-bar>

    <v-main class="fill-height racksnet-background">
      <v-container
        id="login_panel"
        class="fill-height"
        style="max-width: 1280px"
      >
        <v-row col="12" align="center" justify="center">
          <v-col cols="6">
            <v-container style="padding-right: 60px" align="left">
              <v-img
                :src="require('@/assets/icon/racksnet_Logo_Weiss_Invers.png')"
                max-height="200"
                max-width="320"
              ></v-img>
              <h1
                style="
                  font-size: 2.2rem;
                  color: white;
                  font-weight: 300;
                  margin-top: 10px;
                "
              >
                TemplateHub - Browse best-practice templates from vendors
              </h1>
            </v-container>
          </v-col>
          <v-col cols="5">
            <v-card id="loginFormCard" outlined>
              <v-container class="container fill-height">
                <v-alert
                  v-model="$store.state.alerts.show"
                  text
                  width="100%"
                  :type="$store.state.alerts.type"
                  icon="mdi-cloud-alert"
                >
                  {{ $store.state.alerts.message }}
                </v-alert>
                <v-card-text>
                  <v-form>
                    <v-label>New Password</v-label>
                    <v-text-field
                      label="New Password"
                      type="password"
                      name="email"
                      single-line
                      outlined
                      v-model="input.newPassword"
                      :rules="[rules.required, rules.password]"
                    />
                    <v-label>Confirm Password</v-label>
                    <v-text-field
                      label="Confirm Password"
                      type="password"
                      name="email"
                      single-line
                      outlined
                      v-model="input.newPasswordConfirm"
                      :rules="[rules.required]"
                    />
                    <v-btn
                      color="#337fa5"
                      style="color: white"
                      type="button"
                      class="px-12"
                      block
                      v-on:click="resetPassword()"
                      :disabled="!enableButton"
                      :loading="loading"
                    >
                      Reset Password
                    </v-btn>
                  </v-form>
                  <v-list class="mt-3 d-flex justify-end">
                    <router-link to="/login">Return to login page</router-link>
                  </v-list>
                </v-card-text>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import RequestHandler from "@/RequestHandler";

export default {
  name: "ResetPassword",
  data() {
    return {
      input: {
        newPassword: "",
        newPasswordConfirm: "",
      },
      resetToken: "",
      email: "",
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
        password: (value) => {
          const pattern = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,}$/;
          return (
            pattern.test(value) ||
            `Your password must be at least 6 characters long and have to contain at least one letter and one number!`
          );
        },
      },
    };
  },
  computed: {
    enableButton() {
      if (this.input.newPassword.length >= 6 && !this.loading) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    try {
      if (window.self !== window.top) {
        this.$store.state.isIframe = true;
        this.$router.push({ name: "login" });
      } else {
        this.$store.state.isIframe = false;
      }
      let url = new URL(window.location.href);
      if (!url.searchParams.has("token") && !url.searchParams.has("email")) {
        return this.$router.push({ name: "login" });
      }
      this.resetToken = url.searchParams.get("token");
      this.email = url.searchParams.get("email");
    } catch (err) {
      this.$store.state.alerts = {
        show: true,
        message: "Something went wrong!",
        type: "error",
        icon: "mdi-cloud-alert",
      };
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        from ? from : null;
        document.title = to.meta.title;
      },
    },
  },
  methods: {
    async resetPassword() {
      if (this.input.newPassword !== this.input.newPasswordConfirm) {
        this.$store.state.alerts = {
          show: true,
          message: "Both passwords must be equal.",
          type: "error",
          icon: "mdi-cloud-alert",
        };
      } else {
        this.loading = true;
        let data = await RequestHandler.resetPassword(
          this.resetToken,
          this.email,
          this.input.newPassword
        );
        this.loading = false;
        if (!data || data.error) {
          return this.$store.commit("showAlert", {
            message: data.errmsg,
          });
        }
        this.$store.commit("showAlert", {
          message: "The password has been reset successfully.",
          type: "success",
          icon: "mdi-checkbox-marked-circle",
        });
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style>
html {
  padding: 0;
  margin: 0;
  overflow-y: hidden;
}

.racksnet-background {
  background-image: url("../assets/img/Slider_Datacenter.jpg");
}
</style>
