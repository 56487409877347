<template>
  <v-app id="login">
    <v-main class="fill-height racksnet-background">
      <v-container
        id="register_panel"
        class="align-start align-self-start"
        style="max-width: 1280px; padding: 50px"
      >
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <v-img
              :src="require('@/assets/img/racksnet_Logo_white-1024x299.png')"
              max-height="200"
              max-width="320"
            ></v-img>
          </v-col>
          <v-col cols="12" align="center" justify="center">
            <h1 style="font-size: 2.2rem; font-weight: 300; color: white">
              racksnet© TemplateHub
            </h1>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="8">
            <v-card id="registerFormCard" outlined>
              <v-container class="container fill-height">
                <v-alert
                  v-model="$store.state.alerts.show"
                  text
                  width="100%"
                  :type="$store.state.alerts.type"
                  icon="mdi-cloud-alert"
                >
                  {{ $store.state.alerts.message }}
                </v-alert>
                <v-card-text>
                  <v-form v-if="!$store.state.alerts.show">
                    <v-row>
                      <v-col cols="12">
                        <v-label>E-Mail Address</v-label>
                        <v-text-field
                          label="E-Mail Address"
                          type="text"
                          name="email"
                          single-line
                          outlined
                          v-model="email"
                          disabled
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-label>Firstname</v-label>
                        <v-text-field
                          label="Firstname"
                          type="text"
                          name="firstname"
                          single-line
                          outlined
                          v-model="user.firstname"
                          :rules="[rules.required]"
                        />
                      </v-col>
                      <v-col>
                        <v-label>Lastname</v-label>
                        <v-text-field
                          label="Lastname"
                          type="text"
                          name="lastname"
                          single-line
                          outlined
                          v-model="user.lastname"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-label>New Password</v-label>
                        <v-text-field
                          label="New Password"
                          type="password"
                          name="password"
                          single-line
                          outlined
                          v-model="user.password"
                          :rules="[rules.required, rules.password]"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-label>Confirm Password</v-label>
                        <v-text-field
                          label="Confirm Password"
                          type="password"
                          name="passwordConfirm"
                          single-line
                          outlined
                          v-model="user.passwordConfirm"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          color="#337fa5"
                          style="color: white"
                          type="button"
                          class="px-12"
                          block
                          v-on:click="startRegisterUser()"
                          :disabled="!enableButton"
                          :loading="loading"
                        >
                          Register
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                  <div v-else>
                    <v-row class="mb-5">
                      <h2>The registration could not be completed.</h2>
                      <br /><br />
                      <big>
                        Request a new invitation and repeat this process.
                        <br /><br />
                        You can find more help on our website
                        <a href="https://www.racksnet.com" target="_blank"
                          >www.racksnet.com</a
                        >.
                      </big>
                    </v-row>
                    <v-row>
                      <v-list
                        v-if="this.$store.state.isIframe === false"
                        class="mt-3 d-flex justify-end"
                      >
                        <span class="mr-1">Already registered?</span>
                        <router-link to="/login">
                          <span @click="dismissAlert"> Go to Login </span>
                        </router-link>
                      </v-list>
                    </v-row>
                  </div>
                </v-card-text>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import RequestHandler from "@/RequestHandler";

export default {
  name: "ResetPassword",
  data() {
    return {
      user: {
        firstname: "",
        lastname: "",
        password: "",
        passwordConfirm: "",
      },
      token: "",
      email: "",
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
        password: (value) => {
          const pattern = /^(?=.*[A-Za-z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,}$/;
          return (
            pattern.test(value) ||
            `Your password must be at least 6 characters long and have to contain at least one letter and one number!`
          );
        },
      },
    };
  },
  computed: {
    enableButton() {
      if (
        this.loading ||
        this.user.firstname === "" ||
        this.user.lastname === "" ||
        this.email === "" ||
        this.rules["password"](this.user.password) !== true ||
        this.rules["password"](this.user.passwordConfirm) !== true
      ) {
        return false;
      }
      return true;
    },
  },
  async created() {
    try {
      if (window.self !== window.top) {
        this.$store.state.isIframe = true;
        this.$router.push({ name: "login" });
      } else {
        this.$store.state.isIframe = false;
      }
      let url = new URL(window.location.href);
      if (!url.searchParams.has("uid") && !url.searchParams.has("email")) {
        return this.$router.push({ name: "login" });
      }
      this.token = url.searchParams.get("uid");
      this.email = url.searchParams.get("email");
    } catch (err) {
      return this.$store.commit("showAlert", { message: err });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        from ? from : null;
        document.title = to.meta.title;
      },
    },
  },
  methods: {
    dismissAlert() {
      this.$store.state.alerts.show = false;
    },
    async startRegisterUser() {
      if (this.user.password !== this.user.passwordConfirm) {
        return this.$store.commit("showAlert", {
          message: "Both passwords must be equal.",
        });
      } else {
        this.loading = true;
        let data = await RequestHandler.registerUser(
          this.token,
          this.email,
          this.user
        );
        // eslint-disable-next-line no-console
        console.log('# DEBUG startRegisterUser', 3, data);
        this.loading = false;
        if (!data || data.error) {
          return this.$store.commit("showAlert", {
            message: data.errmsg,
          });
        }
        this.$store.commit("showAlert", {
          message: "The account was registered successfully.",
          type: "success",
          icon: "mdi-checkbox-marked-circle",
        });
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style>
html {
  padding: 0;
  margin: 0;
  overflow-y: hidden;
}

.racksnet-background {
  background-image: url("../assets/img/Slider_Datacenter.jpg");
}
</style>
