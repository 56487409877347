import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		alerts: {
			show: false,
			message: "",
			type: "error",
			icon: "mdi-cloud-alert",
		},
		userData: {
			email: "",
			firstname: "",
			lastname: "",
			type: "",
			role: "",
		},
		searchbar: {
			loading: false,
			items: [],
			search: null,
			select: null,
			searchableInput: [],
		},
		navigation: {
			drawer: null,
			collapse: {
				Vendors: false,
				Technologies: false,
			},
			items: [
				{
					icon: "mdi-factory",
					name: "Vendors",
					link: "vendors",
					children: [],
				},
				{
					icon: "$vuetify.icons.racksnetTemplates",
					name: "Technologies",
					link: "technologies",
					children: [],
				},
			],
		},
		isLoggedIn: false,
		isIframe: false,
		isImporting: false,
		fileTypes: {
			cfg: "mdi-file-cog",
			yml: "mdi-file-code",
			html: "mdi-language-html5",
			js: "mdi-language-javascript",
			json: "mdi-json",
			md: "mdi-markdown",
			pdf: "mdi-file-pdf",
			jpg: "mdi-file-image",
			png: "mdi-file-image",
			txt: "mdi-file-document",
			xls: "mdi-file-excel",
		},
	},
	mutations: {
		showAlert(state, { message, type, icon }) {
			state.alerts.show = true;
			state.alerts.message = message || "Something went wrong!";
			state.alerts.type = type || "error";
			state.alerts.icon = icon || "mdi-cloud-alert";
		},
		hideAlert(state) {
			state.alerts.show = false;
			state.alerts.message = "";
			state.alerts.type = "";
			state.alerts.icon = "";
		},
	},
	actions: {},
});
