<template>
  <v-app id="login">
    <v-app-bar
      id="navbar"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#337fa5"
      dark
    >
      <label class="racksnet-nav-label">
        <img
          :src="require('@/assets/img/racksnet_Logo_white-1024x299.png')"
          class="logo-image"
        />
      </label>
    </v-app-bar>

    <v-main class="fill-height racksnet-background">
      <v-container
        id="login_panel"
        class="fill-height"
        style="max-width: 1280px"
      >
        <v-row col="12" align="center" justify="center">
          <v-col cols="6">
            <v-container style="padding-right: 60px" align="left">
              <v-img
                :src="require('@/assets/icon/racksnet_Logo_Weiss_Invers.png')"
                max-height="200"
                max-width="320"
              ></v-img>
              <h1
                style="
                  font-size: 2.2rem;
                  color: white;
                  font-weight: 300;
                  margin-top: 10px;
                "
              >
                TemplateHub - Browse best-practice templates from vendors
              </h1>
            </v-container>
          </v-col>
          <v-col cols="5">
            <v-card id="loginFormCard" outlined>
              <v-container class="container fill-height">
                <v-alert
                  v-model="$store.state.alerts.show"
                  text
                  width="100%"
                  :type="$store.state.alerts.type || 'error'"
                  icon="mdi-cloud-alert"
                >
                  {{ $store.state.alerts.message }}
                </v-alert>
                <v-card-text>
                  <v-form>
                    <v-label>E-Mail Address</v-label>
                    <v-text-field
                      label="E-Mail Address"
                      type="text"
                      name="email"
                      single-line
                      outlined
                      v-model="input.email"
                      :rules="[rules.required, rules.email]"
                    />
                    <v-btn
                      color="#337fa5"
                      style="color: white"
                      type="button"
                      class="px-12"
                      block
                      v-on:click="forgotPassword()"
                      :disabled="!enableButton"
                      :loading="loading"
                    >
                      Reset Password
                    </v-btn>
                  </v-form>
                  <v-list class="mt-3 d-flex justify-end">
                    <span class="mr-1">Already have an account?</span>
                    <router-link to="/login">
                      <span @click="dismissAlert"> Sign in </span>
                    </router-link>
                  </v-list>
                </v-card-text>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import RequestHandler from "@/RequestHandler";

export default {
  name: "ForgotPassword",
  data() {
    return {
      input: {
        email: "",
      },
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  computed: {
    enableButton() {
      if (this.input.email.length > 0 && !this.loading) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    try {
      if (window.self !== window.top) {
        this.$store.state.isIframe = true;
        //this.$router.push({ name: "login" });
      } else {
        this.$store.state.isIframe = false;
      }
    } catch (err) {
      this.$store.state.alerts = {
        show: true,
        message: "Something went wrong!",
        type: "error",
        icon: "mdi-cloud-alert",
      };
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        from ? from : null;
        document.title = to.meta.title;
      },
    },
  },
  methods: {
    dismissAlert() {
      this.$store.state.alerts.show = false;
    },
    async forgotPassword() {
      if (this.input.email === "") {
        this.$store.state.alerts = {
          show: true,
          message: "Requires a verified email address.",
          type: "error",
          icon: "mdi-cloud-alert",
        };
      } else {
        this.loading = true;
        let data = await RequestHandler.forgotPassword(this.input.email);
        if (data.status) {
          this.$store.state.alerts = {
            show: true,
            message: "Email has been sent. Please check your email inbox.",
            type: "success",
            icon: "mdi-checkbox-marked-circle",
          };
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style>
html {
  padding: 0;
  margin: 0;
  overflow-y: hidden;
}

.racksnet-background {
  background-image: url("../assets/img/Slider_Datacenter.jpg");
}
</style>
