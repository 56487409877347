import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router.js";
import store from "./store.js";

Vue.use(vuetify);
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// if (this.$cookies.get("token")) {
		if (sessionStorage.getItem("token")) {
			next(); // go to wherever I'm going
		} else {
			next({ name: "login" });
		}
	} else {
		next(); // does not require auth, make sure to always call next()!
	}
});

new Vue({
	vuetify,
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
