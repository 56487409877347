 <template>
  <v-dialog v-model="modal.active" width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ modal.name }}</span>
      </v-card-title>
      <v-card-text>
        <v-alert
          type="warning"
          icon="mdi-alert"
          prominent
          v-if="this.$store.state.isIframe === false"
        >
          <strong>Warning:</strong> Please close the App Whiteboard before
          importing to avoid import problems!
        </v-alert>
        <v-row>
          <v-col cols="12">
            <label>Select a tenant:</label>
            <br />
            <v-select
              v-model="selectedTenant"
              :items="tenants"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="company"
              item-value="ruid"
              placeholder="Please select..."
              multiple
              small-chips
              dense
              outlined
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider style="margin: 8px 0"></v-divider>
        <h4>These templates will be imported:</h4>
        <v-row>
          <v-col
            v-for="file in folder.children"
            :key="file.name"
            cols="12"
            sm="4"
            md="3"
            lg="3"
          >
            <v-list-item align="center" class="rn-file">
              <v-list-item-content>
                <v-icon style="font-size: 48px">mdi-file</v-icon>
                <v-list-item-title style="font-size: 13px">
                  {{file.name}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="modal.active = false">
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          style="color: white"
          @click="startTemplateImport(folder)"
          :disabled="!(this.selectedTenant.length > 0)"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RequestHandler from "../../RequestHandler";
// import SessionHandler from "@/SessionHandler";

export default {
  name: "template-import",
  props: {
    files: Array,
    folder: Object,
    notification: Object,
  },
  data() {
    return {
      modal: {
        name: "Import to racksnet© cloud",
        active: false,
      },
      selectedTenant: [],
      tenants: [],
    };
  },

  mounted() {
    this.tenants = JSON.parse(sessionStorage.getItem("th_customers"));
    this.$store.state.isImporting = false;
    this.$root.$on("openTemplateImport", () => {
      this.openTemplateImport();
    });
  },

  methods: {
    openTemplateImport() {
      this.modal.active = true;
    },

    showNotification(message, color, icon) {
      this.$store.state.isImporting = false;
      this.$root.$emit("showNotification", message, color, icon);
    },

    updateValue(key, value) {
      this.$root.$emit(key, value);
    },

    startTemplateImport(file) {
      if (this.selectedTenant && this.selectedTenant.length > 0) {
        let importToTenants = this.tenants.filter((obj) =>
          this.selectedTenant.includes(obj.ruid)
        );
        this.modal.active = false;
        this.$store.state.isImporting = true;

        let variant = sessionStorage.getItem("variant")
        if (window.self !== window.top && (this.$store.state.userData.userType === "HUBUSER" || variant === 'onprem')) {
          let message = {
            action: 'import_template',
            template_ids: [file.template_id],
            targetTenants: importToTenants
          }
          window.top.postMessage(message, '*');
          this.showNotification(`Starting import of templates ...`, "info", "mdi-information-outline");
        } else {
          RequestHandler.importTemplates(
            [file.template_id],
            importToTenants,
            this.showNotification
          );
        }
      } else {
        alert("No tenants selected");
      }
    },
  },
};
</script>