<template>
  <v-app id="dashboard">
    <global-app-bar></global-app-bar>
    <v-main class="fill-height">
      <v-snackbar
        v-model="notification.show"
        :color="notification.color"
        absolute
        top
      >
        <v-icon
          v-if="notification.icon"
          color="white"
          style="margin-right: 10px"
          >{{ notification.icon }}</v-icon
        >
        {{ notification.message ? notification.message : "" }}
        <v-spacer />
      </v-snackbar>
      <v-container style="padding-top: 3%">
        <v-row>
          <v-col>
            <v-card style="padding: 20px">
              <v-card-title class="headline mb-5">
                <v-btn icon outlined class="mr-2" to="/files">
                  <v-icon size="32">mdi-chevron-left</v-icon></v-btn
                >
                <label class="ml-2">My Profile</label>
              </v-card-title>
              <v-card-text>
                <v-tabs vertical>
                  <v-tab class="justify-start">
                    <v-icon left> mdi-account </v-icon>
                    Personal Information
                  </v-tab>
                  <v-tab class="justify-start">
                    <v-icon left> mdi-lock </v-icon>
                    API-Key
                  </v-tab>

                  <v-tab-item>
                    <v-card flat style="padding-left: 40px">
                      <v-card-text class="pa-0">
                        <v-row>
                          <v-col cols="8">
                            <v-text-field
                              v-model="$store.state.userData.email"
                              :rules="[rules.email]"
                              label="E-mail"
                              disabled
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="$store.state.userData.firstname"
                              label="First name"
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="$store.state.userData.lastname"
                              label="Last name"
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="pa-0">
                        <v-btn color="blue" dark v-on:click="saveChanges()">
                          Save changes
                        </v-btn>
                      </v-card-actions>
                      <br />
                      <v-divider></v-divider>
                      <br />
                      <v-card-text class="pa-0">
                        <v-form @submit.prevent>
                          <v-row>
                            <v-col cols="8">
                              <v-text-field
                                v-model="oldPassword"
                                label="Current Password"
                                outlined
                                :rules="[rules.required]"
                                :append-icon="
                                  !showOldPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="showOldPassword ? 'text' : 'password'"
                                @click:append="
                                  showOldPassword = !showOldPassword
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="newPassword"
                                label="New Password"
                                outlined
                                :rules="[rules.required, rules.password]"
                                :append-icon="
                                  !showNewPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="showNewPassword ? 'text' : 'password'"
                                @click:append="
                                  showNewPassword = !showNewPassword
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="newPasswordConfirm"
                                label="Confirm Password"
                                outlined
                                :rules="[rules.required, rules.password]"
                                :append-icon="
                                  !showNewPasswordConfirm
                                    ? 'mdi-eye'
                                    : 'mdi-eye-off'
                                "
                                :type="
                                  showNewPasswordConfirm ? 'text' : 'password'
                                "
                                @click:append="
                                  showNewPasswordConfirm =
                                    !showNewPasswordConfirm
                                "
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                      <v-card-actions class="pa-0">
                        <v-btn color="blue" dark v-on:click="changePassword()">
                          Change Password
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item v-if="hasPermission">
                    <v-card flat style="padding-left: 40px">
                      <v-card-text class="pa-0">
                        <v-row>
                          <v-col cols="4">
                            <label>License Type:</label>
                            <v-chip class="ma-2">
                              {{ $store.state.userData.userType }}
                            </v-chip>
                          </v-col>
                        </v-row>
                        <v-row class="align-baseline">
                          <v-col cols="8">
                            <v-text-field
                              v-model="$store.state.userData.apikey"
                              label="TemplateHub Api Key"
                              outlined
                              readonly
                              :append-icon="
                                !showApiKey ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :type="showApiKey ? 'text' : 'password'"
                              @click:append="showApiKey = !showApiKey"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="pa-0">
                        <v-btn color="blue" dark v-on:click="copyApiKey()">
                          Copy to clipboard
                        </v-btn>
                        <v-btn color="red" outlined dark @click="renewApiKey()">
                          Renew
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import RequestHandler from "@/RequestHandler";
import SessionHandler from "@/SessionHandler";
import GlobalAppBar from "@/components/GlobalAppBar.vue";

export default {
  components: { GlobalAppBar },
  name: "Profile",
  data: () => ({
    isDataLoaded: false,
    showApiKey: false,
    hasPermission: false,
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    showOldPassword: "",
    showNewPassword: "",
    showNewPasswordConfirm: "",
    notification: {
      show: false,
      message: "",
      color: "",
      icon: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
      password: (value) => {
        const pattern = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,}$/;
        return (
          pattern.test(value) ||
          `Your password must be at least 6 characters long and have to contain at least one letter and one number!`
        );
      },
      email: (value) => {
        if (/.+@.+\..+/.test(value)) return true;
        return "E-mail must be valid.";
      },
    },
  }),
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        from ? from : null;
        document.title = to.meta.title;
      },
    },
  },
  async created() {
    try {
      if (!SessionHandler.getSession()) {
        return SessionHandler.handleExpiredSession(true);
      }
      // request data
      SessionHandler.loadExistingSession();
      this.$store.state.userData = await RequestHandler.getUserData();
      if (this.$store.state.userData.userType !== "HUBUSER") {
        return this.$router.push("/home");
      }
      this.hasPermission = true;
      this.isDataLoaded = true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  },
  mounted() {
    this.$root.$on("showNotification", (message, color, icon) => {
      this.showNotification(message, color, icon);
    });
  },
  methods: {
    logout() {
      sessionStorage.removeItem("th_breadcrumbs");
      sessionStorage.removeItem("th_customers");
      SessionHandler.handleExpiredSession(false);
    },
    goToFiles(tab) {
      if (tab) {
        return this.$router.push({ name: "files", params: { tab } });
      } else {
        return this.$router.push({ name: "files" });
      }
    },
    async copyApiKey() {
      try {
        if (this.$store.state.userData.apikey === "") throw new Error();
        await navigator.clipboard.writeText(this.$store.state.userData.apikey);
        this.showNotification("Successful coppied to clipboard!", "success");
      } catch ($e) {
        this.showNotification("Cannot copy API Key!", "error");
      }
    },
    async renewApiKey() {
      await RequestHandler.generateNewApiKey();
    },
    async saveChanges() {
      if (
        this.$store.state.userData.firstname === "" ||
        this.$store.state.userData.lastname === ""
      ) {
        return this.showNotification("Error: Some fields are empty!", "error");
      }
      let data = {
        type: "userdata",
        fields: {
          firstname: this.$store.state.userData.firstname,
          lastname: this.$store.state.userData.lastname,
        },
      };
      await RequestHandler.updateUserData(data);
    },

    async changePassword() {
      if (
        this.oldPassword === "" ||
        this.newPassword === "" ||
        this.newPasswordConfirm === ""
      ) {
        return this.showNotification("Error: Some fields are empty!", "error");
      }
      if (this.newPassword !== this.newPasswordConfirm) {
        return this.showNotification(
          "The password and confirm password must be the same!",
          "error"
        );
      }
      let data = {
        type: "password",
        fields: {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        },
      };
      await RequestHandler.updateUserData(data);
    },
    async showNotification(message, color, icon) {
      this.notification.show = true;
      this.notification.message = message ? message : "";
      this.notification.color = color ? color : "";
      this.notification.icon = icon ? icon : "";
    },
  },
};
</script>

<style>
#app-bar {
  padding-left: 0;
  padding-right: 3%;
}
#app-bar .logo-wrapper {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}
#app-bar .racksnet-nav-label {
  min-width: 300px;
  margin-left: 5px;
  padding-left: 0 !important;
}
#app-bar .logo-image {
  max-width: 96px;
  max-height: 96px;
}
.theme--light.v-application {
  background: #ececec;
}
</style>
