<template>
  <v-app id="dashboard">
    <!-- APP NAVBAR BEGINN -->
    <global-app-bar
      :key="isDataLoaded"
      v-on:openDirectory="openDirectory"
      v-on:changeCategory="changeCategory"
      v-on:searchTemplates="searchTemplates"
      showSearchbar
      showSidebar
      :files.sync="originalFiles"
      :currentState="currentState"
    ></global-app-bar>
    <!-- APP CONTENT BEGINN -->
    <v-main>
      <v-breadcrumbs
        class="racksnet-breadcrumb"
        :items="breadcrumb.links"
        :divider="breadcrumb.divider"
      >
        <template v-slot:item="props">
          <v-breadcrumbs-item
            :class="[props.item.disabled && 'disabled']"
            class="racksnet-breadcrumb-item"
            @click.native="openDirectory(props.item.name, props.item.children)"
            >{{ props.item.name }}</v-breadcrumbs-item
          >
        </template>
      </v-breadcrumbs>
      <v-row align="start" justify="center" class="content-view pb-10">
        <v-snackbar
          v-model="notification.show"
          :color="notification.color"
          absolute
          top
        >
          <v-icon
            v-if="notification.icon"
            color="white"
            style="margin-right: 10px"
            >{{ notification.icon }}</v-icon
          >
          {{ notification.message ? notification.message : "" }}
          <v-spacer />
        </v-snackbar>
        <v-progress-circular
          v-if="!isDataLoaded"
          :size="50"
          indeterminate
          color="primary"
          align="center"
          justify="center"
        ></v-progress-circular>
        <folders-component
          v-else-if="isDataLoaded && view === 'folder'"
          v-on:updateBreadcrumbs="updateBreadcrumbs"
          v-on:openDirectory="openDirectory"
          :files="currentState"
          :notification="notification"
          :category="currentCategory"
        ></folders-component>
        <technology-component
          v-else-if="isDataLoaded && view === 'technology'"
          v-on:openDirectory="openDirectory"
          :files="currentState"
          :folder="currentFile"
          :notification="notification"
          :userData="$store.state.userData"
        ></technology-component>
      </v-row>
    </v-main>
    <!-- APP CONTENT END -->
  </v-app>
</template>

<script>
import RequestHandler from "@/RequestHandler";
import SessionHandler from "@/SessionHandler";

import FoldersComponent from "@/components/FoldersComponent.vue";
import TechnologyComponent from "@/components/TechnologyComponent.vue";
import GlobalAppBar from "@/components/GlobalAppBar.vue";

export default {
  name: "Files",
  components: {
    FoldersComponent,
    TechnologyComponent,
    GlobalAppBar,
  },
  data: () => ({
    isDataLoaded: false,
    originalFiles: null,
    filesystem: [],
    currentCategory: "", // share observed category of the filesystem (e.g. Vendors, Technologies, ...)
    currentFile: null, // share observed file to other components
    currentState: [], // naviagtion via folders and breadcrumbs by getting states
    view: "folder",
    notification: {
      show: false,
      message: "",
      color: "",
      icon: "",
    },
    breadcrumb: {
      divider: ">",
      links: [],
    },
  }),
  async mounted() {
    try {
      if (!SessionHandler.getSession()) {
        return SessionHandler.handleExpiredSession(true);
      }
      // request data
      SessionHandler.loadExistingSession();
      this.$store.state.userData = await RequestHandler.getUserData();
      this.originalFiles = await RequestHandler.getFilesystem();
      this.filesystem = this.originalFiles.vendors;
      this.isDataLoaded = true;

      // handle page reload
      if (this.$route.params.tab) {
        for (const [key] of Object.entries(this.$store.state.navigation.collapse)) {
          this.$store.state.navigation.collapse[key] = false;
        }
        this.$store.state.navigation.collapse[this.$route.params.tab] = true;
        this.openDirectory(this.$route.params.tab);
      } else if (sessionStorage.getItem("th_breadcrumbs")) {
        var bc = JSON.parse(sessionStorage.getItem("th_breadcrumbs"));
        if (bc.length === 0) return;

        let category = sessionStorage.getItem("th_current_category");
        for (const [key] of Object.entries(this.$store.state.navigation.collapse)) {
          this.$store.state.navigation.collapse[key] = false;
        }
        this.$store.state.navigation.collapse[category] = true;
        this.filesystem = this.originalFiles[category.toLowerCase()];
        this.breadcrumb.links = bc;

        if (bc[bc.length - 1].isSearch) {
          this.$store.state.searchbar.select = bc[bc.length - 1].searchText;
          this.searchTemplates();
        } else {
          var found = false;
          for (var i = 0; i < bc[bc.length - 1].children.length; i++) {
            if (bc[bc.length - 1].children[i].type === "file") {
              found = true;
              break;
            }
          }
          if (!found) {
            this.openDirectory(
              bc[bc.length - 1].name,
              bc[bc.length - 1].children
            );
          } else {
            var current_bc = bc[bc.length - 1];
            this.openDirectory(
              bc[bc.length - 2].name,
              bc[bc.length - 2].children
            );
            for (var j = 0; j < this.currentState.length; j++) {
              if (this.currentState[j].name === current_bc.name) {
                this.openTechnologyPage(this.currentState[j]);
                break;
              }
            }
          }
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    this.$root.$on("openTechnologyPage", (file) => {
      this.openTechnologyPage(file);
    });
    this.$root.$on("showTemplateInfo", (file) => {
      this.showTemplateInfo(file);
    });
    this.$root.$on("showNotification", (message, color, icon) => {
      this.showNotification(message, color, icon);
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        from ? from : null;
        document.title = to.meta.title;
      },
    },
  },
  methods: {
    logout() {
      sessionStorage.removeItem("th_breadcrumbs");
      sessionStorage.removeItem("th_customers");
      SessionHandler.handleExpiredSession(false);
    },
    /**
     *
     */
    async searchTemplates() {
      if (!this.$store.state.searchbar.select) return;
      let input = this.$store.state.searchbar.select;
      let files = await RequestHandler.searchTemplates(input);
      this.changeCategory("Search", files);
    },
    /**
     * Change the top-level category of the filesystem, for example vendor or technology.
     *
     * @param {String} link   Unique name of a folder.
     * @param {Object} files  This should be the top-level architecture of the filesystem.
     */
    async changeCategory(link, files) {
      let filesystem = this.originalFiles;
      if (!filesystem || filesystem.length === 0) {
        filesystem = await RequestHandler.getFilesystem();
      }
      this.isDataLoaded = true;
      this.view = "folder";

      switch (link.toLowerCase()) {
        case "vendors": {
          this.filesystem = filesystem.vendors;
          this.currentFile = null;
          this.currentCategory = "Vendors";
          this.updateSelectedFiles([]);
          this.setBreadcrumb(this.currentCategory, this.filesystem);
          this.currentState = [...filesystem.vendors.children];
          // restore when reloading the page
          sessionStorage.setItem("th_current_category", "Vendors");
          sessionStorage.setItem(
            "th_breadcrumbs",
            JSON.stringify(this.breadcrumb.links)
          );
          break;
        }
        case "technologies": {
          this.filesystem = filesystem.technologies;
          this.currentFile = null;
          this.currentCategory = "Technologies";
          this.updateSelectedFiles([]);
          this.setBreadcrumb(this.currentCategory, this.filesystem);
          this.currentState = [...filesystem.technologies.children];
          // restore when reloading the page
          sessionStorage.setItem("th_current_category", "Technologies");
          sessionStorage.setItem(
            "th_breadcrumbs",
            JSON.stringify(this.breadcrumb.links)
          );
          break;
        }
        case "search": {
          this.currentFile = null;
          this.currentCategory = "Search";
          this.updateSelectedFiles([]);
          this.setBreadcrumb(
            `Search results for '${this.$store.state.searchbar.select}'`,
            files
          );
          this.currentState = files;
          // restore when reloading the page
          var searchResults = this.breadcrumb.links;
          searchResults[searchResults.length - 1].isSearch = true;
          searchResults[searchResults.length - 1].searchText =
            this.$store.state.searchbar.select;
          sessionStorage.setItem("th_current_category", "Search");
          sessionStorage.setItem(
            "th_breadcrumbs",
            JSON.stringify(searchResults)
          );
          break;
        }
        default: {
          if (!files) files = [...filesystem];
          this.openDirectory(link, files);
        }
      }
    },
    /**
     * Shows the files inside the selected folder and updates the breadcrumbs.
     *
     * @param {String}  link   Unique name of a directory.
     * @param {Array}   state  This contains the files and sub directories of the selected directory.
     * @param {String}  newCategory  This contains the files and sub directories of the selected directory.
     */
    async openDirectory(link, state, newCategory) {
      if (
        link.toLowerCase() === "vendors" ||
        link.toLowerCase() === "technologies"
      ) {
        this.changeCategory(link);
      } else {
        if (!state) return;
        if (newCategory) {
          await this.changeCategory(newCategory);
        }
        this.view = "folder";
        this.isDataLoaded = true;
        this.currentFile = null;
        this.currentCategory =
          newCategory || sessionStorage.getItem("th_current_category");
        this.updateSelectedFiles([]);
        this.updateBreadcrumbs(link);
        this.currentState = state;
      }

      // restore when reloading the page
      sessionStorage.setItem("th_current_category", this.currentCategory);
      sessionStorage.setItem(
        "th_breadcrumbs",
        JSON.stringify(this.breadcrumb.links)
      );
    },
    /**
     * Set a starting point for the breadcrumbs.
     *
     * @param {String} name    Name of the directory.
     * @param {Array} state   This contains the files and sub directories of the selected directory.
     */
    setBreadcrumb(name, state) {
      if (name) {
        this.breadcrumb.links = [
          {
            name: name,
            disabled: true,
            children: state.children,
          },
        ];
      }
    },
    /**
     * Update the breadcrumbs when changing the level of the folders.
     *
     * @param {String} link   Unique name of a folder.
     */
    async updateBreadcrumbs(link) {
      let files = this.filesystem;

      if (this.breadcrumb.links.length >= 2) {
        let currentLinks = this.breadcrumb.links;

        for (let i = 0; i < this.currentState.length; i++) {
          let state = this.currentState[i];
          if (
            state.name.toLowerCase().localeCompare(link.toLowerCase()) === 0
          ) {
            this.breadcrumb.links.forEach(function (item) {
              item.disabled = false;
            });
            this.breadcrumb.links.push({
              name: state.name,
              disabled: true,
              children: state.children,
            });
            return;
          }
        }
        for (let i = currentLinks.length - 2; i > 2; i--) {
          let stateName = currentLinks[i].name;
          if (stateName.toLowerCase().localeCompare(link.toLowerCase()) === 0) {
            this.breadcrumb.links.splice(i + 1, 1);
            this.breadcrumb.links[i].disabled = true;
            return;
          }
        }
      }
      // eslint-disable-next-line no-console
      // console.log(this.currentCategory, this.filesystem, this.originalFiles);
      this.setBreadcrumb(this.currentCategory, files);
      let breadcrumbs = await this.findPath(link, files.children);
      if (breadcrumbs) {
        this.breadcrumb.links.forEach(function (item) {
          item.disabled = false;
        });
        for (let i = 0; i < breadcrumbs.length; i++) {
          if (i < breadcrumbs.length - 1) {
            this.breadcrumb.links.push({
              name: breadcrumbs[i].name,
              disabled: false,
              children: breadcrumbs[i].children,
            });
          } else {
            this.breadcrumb.links.push({
              name: breadcrumbs[i].name,
              disabled: true,
              children: breadcrumbs[i].children,
            });
          }
        }
      }
    },
    /**
     * Searches for a file/folder in the file system and returns its path.
     *
     * @param {String} link   Unique name of a folder.
     * @param {Object} files  This should be the top-level architecture of the filesystem.
     * @return {Array}        Returns the path to the searched path, if exists
     */
    findPath(link, files) {
      if (!files) return;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.type === "directory" || file.type === "technology") {
          if (file.name.toLowerCase().localeCompare(link.toLowerCase()) === 0) {
            return [
              {
                name: file.name,
                type: file.type,
                children: file.children,
              },
            ];
          } else if (file.children) {
            let result = this.findPath(link, file.children);
            if (result) {
              result.unshift({
                name: file.name,
                type: file.type,
                children: file.children,
              });
              return result;
            }
          }
        }
      }
    },
    /**
     *
     */
    async openTechnologyPage(file) {
      this.view = "technology";
      this.currentFile = file;
      this.updateBreadcrumbs(file.name);
      // restore when reloading the page
      sessionStorage.setItem(
        "th_breadcrumbs",
        JSON.stringify(this.breadcrumb.links)
      );
    },
    /**
     *
     */
    async showTemplateInfo(file) {
      await this.openDirectory(file.vendor);
      this.currentFile = file;
      let breadcrumbs = this.breadcrumb.links;
      for (let i = 0; i < breadcrumbs.length; i++) {
        breadcrumbs[i].disabled = false;
      }
      breadcrumbs.push({
        text: file.name,
        link: true,
        disabled: true,
        children: null,
      });
    },
    /**
     *
     */
    async showNotification(message, color, icon) {
      this.notification.show = true;
      this.notification.message = message ? message : "";
      this.notification.color = color ? color : "";
      this.notification.icon = icon ? icon : "";
    },
    /**
     *
     */
    updateSelectedFiles(files) {
      this.$root.$emit("updateSelectedFiles", files);
    },
  },
};
</script>

<style scoped>
#app-bar {
  padding-left: 0;
  padding-right: 3%;
}
#app-bar .logo-wrapper {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}
#app-bar .racksnet-nav-label {
  min-width: 300px;
  margin-left: 5px;
  padding-left: 0 !important;
}
#app-bar .logo-image {
  max-width: 96px;
  max-height: 96px;
}

#sidebar .active {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
}
#sidebar .active i {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
}
.racksnet-breadcrumb {
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 58px;
}
.racksnet-breadcrumb-item {
  color: #337fa5;
  font-weight: 500;
}
.racksnet-breadcrumb-item:hover {
  color: lightblue;
  cursor: pointer;
}
.racksnet-breadcrumb-item.disabled {
  color: #666;
  font-weight: 600;
  pointer-events: none;
}
.theme--light.v-application {
  background: #ececec;
}

.content-view {
  padding-left: 10px;
  margin: auto 0;
  max-height: calc(100vh - 114px);
  overflow-y: scroll;
  height: 93%;
}
</style>
