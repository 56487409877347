import axios from "axios";
axios.defaults.withCredentials = true;
import store from './store.js'
import FileDownload from "js-file-download";
import SessionHandler from "@/SessionHandler";
let url;

if (window.location.hostname === 'localhost') {
  url = "http://localhost:3003/api"
} else if (window.location.hostname === '18.184.237.164') {
  url = "http://18.184.237.164:3000/api"
} else {
  url = window.location.protocol + "//" + window.location.hostname + "/api";
}


class RequestHandler {
  /**
   * Registers a new user with name, email and password.
   * @param {*} token 
   */
  static registerUser(token, email, user) {
    return new Promise(async (resolve, reject) => {
      try {
        axios.post(`${url}/users/create`, {
          resetToken: token,
          email,
          password: user.password,
          firstname: user.firstname,
          lastname: user.lastname 
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (err) {
          reject(err);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Login Process for the application. Send credentials to the server and response a token on success.
   * @param {*} userData 
   */
  static loginProcess(userData) {
    return new Promise(async (resolve, reject) => {
      try {
        axios.post(`${url}/login`, userData, {timeout: 5000})
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Login Process for the application. Send credentials to the server and response a token on success.
   * @param {*} token 
   */
  static tokenAuth(token, licenseType) {
    return new Promise(async (resolve, reject) => {
      try {
        axios.post(`${url}/login-token`, { token, licenseType })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Requests a password reset to the given email.
   * @param {*} userData 
   */
  static forgotPassword(email) {
    return new Promise(async (resolve, reject) => {
      try {
        axios.post(`${url}/forgot-password`, {email}, {timeout: 8000})
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Performs a password reset to the given token and email.
   * @param {*} userData 
   */
  static resetPassword(resetToken, email, password) {
    return new Promise(async (resolve, reject) => {
      try {
        axios.post(`${url}/reset-password`, {resetToken, email, password}, {timeout: 8000})
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (err) {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  static getUserData() {
    return new Promise(async (resolve, reject) => {
      try {
        let user_id = store.state.userData.puid ? store.state.userData.puid : sessionStorage.th_user_id;
        let options = { withCredentials: true }
        if (user_id && user_id !== "undefined") {
          let userData = store.state.userData;
          let variant = sessionStorage.getItem("variant")
          if (userData.userType === "HUBUSER" || variant === 'onprem') {
            options["headers"] = {"Authorization": `Bearer ${SessionHandler.getSession()}`}
          }
          axios.get(`${url}/users/${user_id}`, options)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (err) {
              if (err.response && err.response.status === 401) {
                SessionHandler.handleExpiredSession(true);
              }
              reject(err);
            });
        } else if (SessionHandler.getSession()) {
          axios.get(`${url}/user`, options)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (err) {
              if (err.response && err.response.status === 401) {
                SessionHandler.handleExpiredSession(true);
              }
              reject(err);
            });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  static updateUserData(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let user_id = store.state.userData.puid ? store.state.userData.puid : sessionStorage.th_user_id;
        if (user_id) {
          let options = { withCredentials: true }
          axios.put(`${url}/users/${user_id}`, data, options)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (err) {
              if (err.response && err.response.status === 401) {
                SessionHandler.handleExpiredSession(true);
              }
              reject(err);
            });
        } else {
          SessionHandler.handleExpiredSession(true);
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * GET: Response all directories and files in the filesystem.
   *
   */
  static getFilesystem() {
    return new Promise(async (resolve, reject) => {
      try {
        let options = { withCredentials: true }
        let userData = store.state.userData;
        let variant = sessionStorage.getItem("variant")
        if (userData.userType === "HUBUSER" || variant === 'onprem') {
          options["headers"] = {"Authorization" : `Bearer ${SessionHandler.getSession()}`}
        }
        const res = await axios.get(`${url}/files`, options);
        const data = res.data;
        resolve(data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          SessionHandler.handleExpiredSession(true);
        }
        reject(err);
      }
    });
  }

  /**
   * GET: Response all directories and custom templates in the filesystem.
   * @param {*} user_id 
   */
  static getCustomTemplates(user_id) {
    if (user_id) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await axios.get(`${url}/templates/custom/${user_id}`);
          const data = res.data;
          resolve(
            data.map(item => ({
              ...item
            }))
          );
        } catch (err) {
          if (err.response && err.response.status === 401) {
            SessionHandler.handleExpiredSession(true);
          }
          reject(err);
        }
      });
    }
  }

  /**
   * 
   */
  static getAllSearchableKeywords() {
    return new Promise(async (resolve, reject) => {
      try {
        let options = { withCredentials: true }
        let userData = store.state.userData;
        let variant = sessionStorage.getItem("variant")
        if (userData.userType === "HUBUSER" || variant === 'onprem') {
          options["headers"] = {"Authorization" : `Bearer ${SessionHandler.getSession()}`}
        }
        const res = await axios.get(`${url}/keywords`, options);
        const data = res.data;
        resolve(
          data.map(item => ({
            type: 'keyword',
            name: item
          }))
        );
      } catch (err) {
        if (err.response && err.response.status === 401) {
          SessionHandler.handleExpiredSession(true);
        }
        reject(err);
      }
    });
  }

  /**
   * GET: Response all files that are registered in the database.
   */
  static async searchTemplates(keys) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          responseType: "json",
          withCredentials: true
        }
        let userData = store.state.userData;
        let variant = sessionStorage.getItem("variant")
        if (userData.userType === "HUBUSER" || variant === 'onprem') {
          options["headers"] = {"Authorization" : `Bearer ${SessionHandler.getSession()}`}
        }
        await axios
          .post(`${url}/templates/search`, { keywords: keys }, options)
          .then(function (response) {
            const data = response.data;
            resolve(
              data.map(item => ({
                ...item
              }))
            );
          })
          .catch(error => {
            if (error.response && error.response.status === 401) {
              SessionHandler.handleExpiredSession(true);
            }
            return Promise.reject(error);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * GET: Download prefab templates (vendor or technology) and custom templates.
   * 
   * @param {number} template_id
   * @param {string} name
   * @param {function} callback
   */
  static async downloadTemplate(template_id, name, callback) {
    let options = {
      responseType: "blob",
      withCredentials: true
    }
    let userData = store.state.userData;
    let variant = sessionStorage.getItem("variant")
    if (userData.userType === "HUBUSER" || variant === 'onprem') {
      options["headers"] = {"Authorization" : `Bearer ${SessionHandler.getSession()}`}
    }
    await axios.get(`${url}/templates/${template_id}/download`, options)
      .then(response => {
        callback(`Downloading file '${name}' ...`, "info", "mdi-download");
        FileDownload(response.data, name + ".zip");
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          SessionHandler.handleExpiredSession(true);
        }
        else {
          callback(`Cannot download this file. Please resync and try it again.`, "error", "mdi-download-off");
        }
        return Promise.reject(error);
      });
  }

  /**
   * GET: Download prefab templates (vendor or technology) and custom templates.
   * @param {*} template_ids 
   * @param {*} displayName 
   * @param {*} callback 
   */
  static async downloadMultipleTpls(template_ids, displayName, callback) {
    let options = {
      responseType: "blob",
      withCredentials: true
    }
    let userData = store.state.userData;
    let variant = sessionStorage.getItem("variant")
    if (userData.userType === "HUBUSER" || variant === 'onprem') {
      options["headers"] = {"Authorization" : `Bearer ${SessionHandler.getSession()}`}
    }
    await axios
      .post(`${url}/templates/download`, { templates: template_ids }, options)
      .then(response => {
        let alterName = `${new Date().toISOString().substr(0, 19).replace('T', '').replace(/[^\w\s]/gi, '')}-${SessionHandler.getSession().toString()}`;
        let name = displayName ? displayName : alterName;
        callback(`Compressing and downloading files ...`, "info", "mdi-download");
        FileDownload(response.data, name + ".zip");
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          SessionHandler.handleExpiredSession(true);
        }
        else {
          callback(`Cannot download this file. Please resync and try it again.`, "error", "mdi-download-off");
        }
        return Promise.reject(error);
      });
  }

  /**
   * POST: Download prefab templates (vendor or technology) and custom templates.
   * @param {*} templates 
   * @param {*} callback 
   */
  static importTemplates(templates, userdata, callback) {
    return new Promise(async (resolve, reject) => {
      try {
        let options = {
          withCredentials: true
        }
        let hostUrl = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;
        let userData = store.state.userData;
        let variant = sessionStorage.getItem("variant")
        if (userData.userType === "HUBUSER" || variant === 'onprem') {
          options["headers"] = {"Authorization": `Bearer ${SessionHandler.getSession()}`}
        }
        axios.post(`${url}/templates/import`, {templates, userdata, hostUrl}, options)
            .then(response => {
            callback(`The import was performed successfully.`, "success", "mdi-application-import");
            resolve(response.data);
          })
          .catch(error => {
            if (error.response && error.response.status === 401) {
              SessionHandler.handleExpiredSession(true);
            }
            else {
              callback(`The import has failed. Please reload the app and try again!`, "error", "mdi-alert-circle");
            }
            reject(error);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * POST: upload multible files to the server (only custom template).
   * @param {*} formData 
   */
  static uploadTemplate(formData) {
    return new Promise(async (resolve, reject) => {
      try {
        axios({
          method: "post",
          url: `${url}/template/upload`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      } catch (err) {
        if (err.response && err.response.status === 401) {
          SessionHandler.handleExpiredSession(true);
        }
        reject(err);
      }
    });
  }

  /**
   * DELETE: delete a selected file from the server and the database (only custom template).
   * @param {*} user_id 
   * @param {*} template_id 
   */
  static deleteTemplate(user_id, template_id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios
          .delete(`${url}/template/${user_id}/${template_id}`)
          .then(response => {
            resolve(response);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  static generateNewApiKey() {
    return new Promise(async (resolve, reject) => {
      try {
        await axios
          .post(`${url}/users/renew-apikey`)
          .then(response => {
            if (response.data && response.data.success) {
              store.state.userData.apikey = response.data.apiKey
            }
            resolve(response);
          });
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default RequestHandler;
