<template>
  <div>
    <router-view v-if="!isLoading"/>
    <h2 v-else align="center">404 - Something went terribly wrong!</h2> 
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    this.isLoading = false;
  }
};
</script>

<style>
.racksnet-background{
  background-image: url("./assets/img/Slider_Datacenter.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f1f1f7;
  background-attachment: scroll;
}

#navbar {
  padding-left: 3%;
  padding-right: 3%;
}
#navbar .logo-wrapper {
  display: block;
  width: auto;
  height: auto;
  padding: 0;
  margin: 10px 0 0 0;
}
#navbar .racksnet-nav-label {
  min-width: 300px;
  margin-left: 5px;
  padding-left: 0 !important;
}
#navbar .logo-image {
  max-width: 96px;
  max-height: 96px;
}
</style>