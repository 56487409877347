<template>
  <div>
    <v-app-bar
      id="app-bar"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#337fa5"
      dark
    >
      <v-toolbar-title class="racksnet-navbar ml-0">
        <v-app-bar-nav-icon
          v-if="showSidebar"
          @click.stop="
            $store.state.navigation.drawer = !$store.state.navigation.drawer
          "
        />
        <label class="racksnet-nav-label">
          <router-link to="/home">
            <a class="logo-wrapper">
              <img
                :src="require('@/assets/img/racksnet_Logo_white-1024x299.png')"
                class="logo-image"
              />
            </a>
          </router-link>
        </label>
      </v-toolbar-title>
      <v-spacer />
      <v-autocomplete
        v-if="showSearchbar"
        id="searchForm"
        hide-details
        style="max-width: 600px"
        dense
        filled
        outlined
        clearable
        v-model="$store.state.searchbar.select"
        :loading="$store.state.searchbar.loading"
        :items="$store.state.searchbar.searchableInput"
        item-text="name"
        prepend-inner-icon="mdi-magnify"
        append-outer-icon="mdi-send"
        class="hidden-sm-and-down"
        label="Searching for templates..."
        @click:append-outer="searchTemplates"
        @click:clear="changeCategory('Vendors')"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Please enter a
              <strong>keyword (template names, vendor or technology)</strong>
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="data">
          <template v-if="data.item.type === 'file'">
            <v-list-item-avatar>
              <v-icon class="racksnet-icons">mdi-file</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ data.item.vendor }} &bull;
                {{ data.item.technology }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
          <template v-else-if="data.item.type === 'keyword'">
            <v-list-item-avatar>
              <v-icon class="racksnet-icons">{{ "mdi-magnify" }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-spacer />
      <v-btn icon v-on:click="$router.go()">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-menu
        v-if="$store.state.isIframe === false"
        bottom
        min-width="200px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="primary" size="42">
              <span class="white--text text-h6">{{ getInitials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card outlined>
          <v-list-item-content class="justify-center">
            <div class="text-center">
              <div v-if="$store.state.userData.userType === 'HUBUSER'">
                <v-btn link to="/profile" text block>
                  <v-icon>mdi-account</v-icon>
                  Settings
                </v-btn>
                <v-divider class="my-1"></v-divider>
              </div>
              <v-btn text block v-on:click="logout()">
                <v-icon>mdi-logout</v-icon>
                Logout
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <!-- APP NAVI-DRAWER BEGINN -->
    <v-navigation-drawer
      v-if="showSidebar"
      v-model="$store.state.navigation.drawer"
      id="sidebar"
      :clipped="$vuetify.breakpoint.smAndUp"
      app
    >
      <v-list dense rounded>
        <v-list-item link to="/home">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <template v-for="item in $store.state.navigation.items">
          <!-- with children -->
          <v-list-group
            v-if="item.children"
            v-model="$store.state.navigation.collapse[item.name]"
            :id="'v-list--' + item.link.toLowerCase()"
            :key="item.name"
            :prepend-icon="item.icon"
            :value="item.value"
          >
            <template v-slot:activator>
              <v-list-item-content v-on:click="changeCategory(item.link)">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              v-on:click="openDirectory(child.name, child.children, item.name)"
              v-bind:class="{
                active: currentState.name === child.link,
              }"
              style="padding-left: 40px"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- without children -->
          <v-list-item
            v-else
            :key="item.name"
            v-on:click="changeCategory(item.link)"
            v-bind:class="{
              active: currentState.name === item.name,
            }"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-divider class="my-2"></v-divider>
        <!--<v-list-item
          v-if="$store.state.userData.userType === 'HUBUSER'"
          link
          to="/profile"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>My Profile</v-list-item-title>
        </v-list-item>
        <v-list-item link href="mailto:support@racksnet.com">
          <v-list-item-icon>
            <v-icon>mdi-help-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item>-->
      </v-list>
    </v-navigation-drawer>
    <!-- APP NAVI-DRAWER END -->
  </div>
</template>

<script>
import RequestHandler from "@/RequestHandler";
import SessionHandler from "@/SessionHandler";

export default {
  name: "GlobalAppBar",
  props: {
    showSearchbar: Boolean,
    showSidebar: Boolean,
    files: Object,
    currentState: Array,
  },
  data: () => ({}),
  async mounted() {
    // init values
    if (window.self !== window.top) {
      this.$store.state.isIframe = true;
    }
    if (this.showSearchbar) {
      this.$store.state.searchbar.searchableInput =
        await RequestHandler.getAllSearchableKeywords();
    }
    if (this.files && this.files.vendors && this.files.technologies) {
      let vendors = [...this.files.vendors.children];
      let technologies = [...this.files.technologies.children];
      let navItems = this.$store.state.navigation.items;
      for (let i = 0; i < vendors.length; i++) {
        if (vendors[i].type === "directory") {
          vendors[i] = {
            icon: "mdi-folder",
            name: vendors[i].name,
            link: vendors[i].name.toLowerCase(),
            children: vendors[i].children,
          };
        }
      }
      for (let j = 0; j < technologies.length; j++) {
        if (technologies[j].type === "directory") {
          technologies[j] = {
            icon: "mdi-folder",
            name: technologies[j].name,
            link: technologies[j].name.toLowerCase(),
            children: technologies[j].children,
          };
        }
      }
      for (let k = 0; k < navItems.length; k++) {
        if (navItems[k].children) {
          if (navItems[k].link === "vendors") {
            navItems[k].children = vendors;
          } else if (navItems[k].link === "technologies") {
            navItems[k].children = technologies;
          }
        }
      }
    }
  },
  computed: {
    getInitials() {
      let userData = this.$store.state.userData;
      if (this.$store.state.userData.userType === "HUBUSER") {
        return userData.firstname[0] + userData.lastname[0];
      } else if (this.$store.state.userData.userType === "APPUSER") {
        return userData.email[0] + userData.email[1];
      } else {
        return "U";
      }
    },
  },
  methods: {
    openDirectory(link, state, newCategory) {
      this.$emit("openDirectory", link, state, newCategory);
    },
    changeCategory(link, state) {
      this.$emit("changeCategory", link, state);
    },
    searchTemplates() {
      this.$emit("searchTemplates");
    },
    /**
     *
     */
    logout() {
      sessionStorage.removeItem("th_breadcrumbs");
      sessionStorage.removeItem("th_customers");
      SessionHandler.handleExpiredSession(false);
    },
    /**
     *
     */
    customFilter(file, queryText) {
      if (queryText.length < 2) return;
      if (file.type === "file") {
        const name = file.name.toLowerCase();
        const vendor = file.vendor.toLowerCase();
        const technology = file.technology.toLowerCase();
        const searchText = queryText.toLowerCase();

        return (
          name.indexOf(searchText) > -1 ||
          vendor.indexOf(searchText) > -1 ||
          technology.indexOf(searchText) > -1
        );
      } else if (file.type === "keyword") {
        const keyword = file.name.toLowerCase();
        const searchText = queryText.toLowerCase();

        return keyword.indexOf(searchText) > -1;
      }
    },
  },
};
</script>
