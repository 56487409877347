<template>
  <v-container class="file-wrapper">
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card width="100%">
          <div style="text-align: center; border-bottom: 2px solid lightgrey">
            <img
              v-if="folder.cover_img != null"
              height="300"
              style="
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 600px;
              "
              :src="technology_image_url"
            />
            <v-img
              v-else
              height="300"
              :src="require('@/assets/img/Slider_Datacenter.jpg')"
            ></v-img>
          </div>
          <v-list-item class="d-flex flex-column flex-md-row py-3">
            <v-list-item-content class="d-flex align-self-start mr-3">
              <v-list-item-subtitle>
                <small>{{ folder.tpl_type.toUpperCase() }}</small>
              </v-list-item-subtitle>
              <v-list-item-title class="mt-1 mb-2" style="font-size: 1.5rem">
                {{folder ? folder.name : ''}}
              </v-list-item-title>
              <v-list-item-subtitle>
                <label>Tags: </label>
                <v-chip
                  v-for="tag in folder.tags"
                  v-bind:key="tag"
                  class="ma-1"
                  x-small
                  >{{ tag }}</v-chip
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="d-flex ml-0">
              <v-btn
                v-if="allowTemplateImport"
                :dark="!$store.state.isImporting"
                color="blue"
                block
                class="mb-2"
                v-on:click="importTemplates()"
                :disabled="$store.state.isImporting"
              >
                Import to racksnet© cloud
              </v-btn>
              <v-btn
                dark
                color="green"
                block
                class="mb-2"
                v-on:click="downloadTemplate(folder)"
              >
                Download templates
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text>
            <h3>INCLUDED FILES</h3>
            <v-divider style="margin: 8px 0"></v-divider>
            <v-row>
              <v-col
                v-for="file in folder.children"
                :key="file.name"
                cols="12"
                sm="4"
                md="3"
                lg="2"
              >
                <v-list-item
                  align="center"
                  class="rn-file"
                  v-on:click="showTemplateInfo(file)"
                >
                  <v-list-item-content>
                    <v-icon style="font-size: 48px">mdi-file</v-icon>
                    <v-list-item-title style="font-size: 13px" >
                      {{file.name}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <br>
            <h3>DESCRIPTION</h3>
            <v-divider style="margin: 8px 0"></v-divider>
            <div align="justify" v-html="(folder.desc_html && folder.desc_html !== '') ? folder.desc_html : folder.desc_text"></div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <template>
      <v-dialog v-model="dialog.active" width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Preview of {{ dialog.props.name }}</span>
          </v-card-title>
          <v-card-text>
            <v-textarea
              solo
              readonly
              no-resize
              height="300"
              name="input-7-4"
              :value="dialog.props.content"
              style="font-size: 12px"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog.active = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template-import
      v-if="allowTemplateImport"
      :files="files"
      :folder="folder"
      :notification="notification"
    >
    </template-import>
  </v-container>
</template>

<script>
import RequestHandler from "@/RequestHandler";
import TemplateImport from "./modals/TemplateImport.vue";

export default {
  name: "technology-component",
  components: {
    TemplateImport,
  },
  props: {
    files: Array,
    folder: Object,
    notification: Object,
    userData: Object
  },
  data() {
    return {
      technology_image_url: null,
      allowTemplateImport: false,
      dialog: {
        active: false,
        props: {
          name: "",
          content: "",
        },
      },
    };
  },

  mounted() {
    // load background image
    if (this.folder.cover_img) {
      this.technology_image_url = process.env.VUE_APP_TPL_BASEURL + this.folder.cover_img;
    }
    // load import button
    // eslint-disable-next-line no-console
    console.log(this.$store.state.userData);
    if (this.$store.state.userData.userType === "APPUSER" || window.self !== window.top) {
      this.allowTemplateImport = true;
    } else {
      this.allowTemplateImport = false;
    }
  },

  methods: {
    getTechnologyName(technology, sub) {
      let name = "";
      if (sub && sub !== "Unnamed") {
        if (sub.includes(technology)) {
          name = `${technology} ${sub.replace(technology, '')}`
        } else {
          name = sub
        }
      } else {
        name = technology
      }
      return name
    },

    openDirectory(link, newState) {
      this.$emit("openDirectory", link, newState);
    },

    openTechnologyPage(folder) {
      this.$root.$emit("openTechnologyPage", folder);
    },

    showTemplateInfo(file) {
      this.dialog.active = true;
      this.dialog.props.name = file.name;
      this.dialog.props.content = file.content;
      //this.$root.$emit("showTemplateInfo", file);
    },

    showNotification(message, color, icon) {
      this.$root.$emit("showNotification", message, color, icon);
    },

    downloadTemplate(file) {
      RequestHandler.downloadTemplate(
        file.template_id,
        `${file.vendor}_${file.tags[1]}_${file.technology}_${file.name}`,
        this.showNotification
      );
    },

    importTemplates() {
      this.$root.$emit("openTemplateImport");
    },
  },
};
</script>

<style scoped>
@media (max-width: 786px) {
  .file-wrapper {
    padding: 5px 10px;
  }
}

.rn-file {
  border: 1px solid #ddd;
}

.v-chip {
  margin-left: 0px !important;
}
</style>
